import {createTheme} from "@mui/material";
import {SPColors} from "../reusable/SPColors";
export const appTheme = createTheme({
    palette: {
        primary: {
            main: SPColors.primary,
            contrastText: SPColors.text_secondary,
        },
        secondary: {
            main: SPColors.text_secondary,
            contrastText: SPColors.text_secondary,
        },
        success: {
            main: SPColors.success,
            contrastText: SPColors.success,
        },
        warning: {
            main: SPColors.warning,
            contrastText: SPColors.warning,
        },
        error: {
            main: SPColors.danger,
            contrastText: SPColors.danger,
        },
        action: {
            active: SPColors.primary,
            disabledOpacity: 0.6,
            activatedOpacity: 0.95,
            focus: SPColors.primary,
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    "&$disabled": {
                        backgroundColor: "rgba(120, 120, 120, 0.1)",
                    },
                },
                disabled: {
                    backgroundColor: "rgba(120, 120, 120, 0.1)",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1.15rem",
                },
                formControl: {
                    fontSize: "1.15rem",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    fontSize: "1.15rem",
                },
            },
            variants: [
                {
                    props: {disabled: true},
                    style: {
                        backgroundColor: "rgba(120, 120, 120, 0.075)",
                    },
                },
            ],
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
            defaultProps: {
                InputLabelProps: {shrink: true},
                variant: "outlined",
                autoComplete: "false",
            },
        },
        MuiTabs: {
            defaultProps: {
                indicatorColor: "primary",
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0, 0, 0, 0.6)",
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                    fontWeight: 400,
                    fontSize: "1rem",
                    lineHeight: "1.4375em",
                    letterSpacing: "0.00938em",
                    padding: 0,
                    display: "block",
                    transformOrigin: "top left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 24px)",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    transform: "translate(14px, 16px) scale(1)",
                    transition:
                        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    zIndex: 1,
                    pointerEvents: "none",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "100%",
                },
            },
        },
        MuiGrid: {
            defaultProps: {
                xs: 12,
                columnSpacing: 2,
                rowSpacing: 2,
                item: true,
            },
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                },
            },
        },
        MuiGrid2: {
            defaultProps: {
                xs: 12,
                columnSpacing: 2,
                rowSpacing: 2,
            },
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                },
            },
        },
    },
});
