//import Components
import {ReactElement} from "react";
type props = {
    className?: string,
    children?: ReactElement | ReactElement[],
}

export default function ContentWrapper({className, ...props}:props) {
    return (
        <>
            <main className={`contentWrapper ${className}`}>
                {props.children}
            </main>
        </>
    )
}
