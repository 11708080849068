export const SPColors = {
    primary: "#43c1e8",
    success: "#27c24c",
    danger: "#f05050",
    warning: "#ff902b",
    text_primary: "#383737",
    text_secondary: "#acb9be",
    footerBackground: "#f5f5f5",
    tutti1: "#EC6B0B",
    tutti2: "#EB5325",
    tutti3: "#E82D4E",
    tutti4: "#E5007E",
};
