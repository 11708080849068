//import React components

//import components
import PageContainer from "./PageContainer";

//callable functions

//font awesome
// import TopNav from "./navigation/TopNav";
import Footer from "./footer/Footer";

//linear-gradient(90deg,${headerColors.header.gradient_left} 0,${headerColors.header.gradient_right})

export default function PageLayout({...props}) {
    return (
        <>
            {/*<TopNav />*/}
            <PageContainer>
                <div className={"pageContainer"}>{props.children}</div>
            </PageContainer>
            <Footer />
        </>
    );
}
