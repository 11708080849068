import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import App from "./App";
import reportWebVitals from "./tests/reportWebVitals";
import {AppDataProvider} from "./context/data";
import {AppStateProvider} from "./context/state";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {appTheme} from "./styles/styles";
import i18n from "./i18n";
import "moment/locale/nb";
import "moment/locale/en-gb";
import moment from "moment";
i18n.init();

const momentLocaleMap: Record<string, string> = {
    no: "nb",
    en: "en-gb",
};
moment.locale(momentLocaleMap[i18n.locale || "no"]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <AppStateProvider>
            <ThemeProvider theme={appTheme}>
                <AppDataProvider>
                    <BrowserRouter>
                        {/*Application render*/}
                        <App />
                    </BrowserRouter>
                </AppDataProvider>
            </ThemeProvider>
        </AppStateProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
