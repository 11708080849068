//import Components
import React, {createContext, useContext, useState} from "react";

//----------------[This is code for global variables across all pages]---------------------//

export type AppStateProps = {
    sideMenuOpen?: boolean;
    user_error?: string | false;
    processing?: boolean;
    redirect_ref?: string;
    [key: string]: any;
};

export type appContextInterface =
    | {
          appState: AppStateProps | undefined;
          setAppState: React.Dispatch<React.SetStateAction<AppStateProps | undefined>>;
      }
    | undefined;

const AppContext = createContext<appContextInterface>(undefined);

export function AppStateProvider({...props}) {
    const [appState, setAppState] = useState<AppStateProps>();

    return (
        <>
            <AppContext.Provider value={{appState, setAppState}}>{props.children}</AppContext.Provider>
        </>
    );
}

export function useAppState() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppState must be within AppStateProvider");
    }
    return context;
}
