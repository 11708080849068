//import Components
import {createContext, useContext, useState} from "react";

//----------------[This is code for global variables across all pages]---------------------//

type AppDataProps = {}

export interface appDataInterface {
    appData: AppDataProps | undefined,
    setAppData: (data: any) => void
}

const AppContext = createContext<appDataInterface>({
    appData: undefined,
    setAppData: (data: AppDataProps) => void data,
});


export function AppDataProvider({...props}) {
    const [appData, setAppData] = useState<AppDataProps>();


    return (
        <>
            <AppContext.Provider value={{appData, setAppData}}>
                {props.children}
            </AppContext.Provider>
        </>
    )
}

export function useAppData() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppState must be within AppStateProvider");
    }
    return context;
}
