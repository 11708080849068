//node modules
import React from "react";
import ContentWrapper from "../../layout/ContentWrapper";
import useSPTranslation from "../../reusable/useSPTranslation";

//hooks & functions

//components

//validators
type Props = {};

const Confirmation: React.FC<Props> = () => {
    const {t} = useSPTranslation();

    return (
        <ContentWrapper>
            <div>{t("enrollment.sent")}</div>
        </ContentWrapper>
    );
};

export default Confirmation;
