import {useTranslation} from "react-i18next";
import i18next, {TOptions} from "i18next";
import {useCallback} from "react";

/** Translation based on styreportalen's structure. supports organizations and federations and fallback to default translation if none contains.
 * @Params nameSpace file to use first, fallbackNameSpace file to fall back to as secondary.
 * @nameSpace String.
 * @fallbackNameSpace string.
 * @Return translation or defaultValue
 * If none of primary or secondary finds key it uses default translation
 * */
export default function useSPTranslation(nameSpace?: string, fallBackNameSpace?: string) {
    const {t} = useTranslation(nameSpace);
    const {t: t2} = useTranslation(fallBackNameSpace, {useSuspense: true});

    const translator: (key: string, defaultValue?: string | undefined, options?: TOptions) => any = useCallback(
        (key: string, defaultValue?: string | undefined, options?: TOptions) => {
            let result = t(key, defaultValue || key, options);

            if (result === key) {
                result = t2(key, defaultValue || key, options);
            }

            return result;
        },
        [t, t2]
    );

    return {t: translator};
}

/** Translation function with optional namespace, this works like normal translation except it can use namespace then fallback to default translation and only then default to defaultValue*/
export function spTranslate(key: string, defaultValue?: string, options?: TOptions, nameSpace?: string) {
    return nameSpace ? i18next.t(`${nameSpace}:${key}`, options as TOptions) : i18next.t(key, options as TOptions);
}
